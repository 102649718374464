import { StaticRouterContext } from 'react-router';
import { HttpStatusCode } from '@cp/utils/const/httpStatusCode';

export enum AdminRoutePath {
  Home = '/',
  ModerationClientPatronage = '/moderation/client-patronage',
  ModerationsLegacyClients = '/moderations/clients/:id?',
  ModerationsLegacyCatalogItems = '/moderations/catalog/items/:id?',
  ServiceCaching = '/service/caching',
  Messages = '/messages/:dialogId?',
  Metrics = '/metrics',
  ResponseDelay = '/metrics/response-delay',
  Specialists = '/clients/specialists/:id?',
  Employers = '/clients/employers/:id?',
  Vacancies = '/vacancies/:id?',
  RoutesMeta = '/routes',
}

export interface AppStaticRouterContext extends StaticRouterContext {
  statusCode?: HttpStatusCode;
}
