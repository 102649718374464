import React from 'react';
import { feedbackSign } from '@common/model/catalog/feedback';
import { Stack } from '@cp/ds/src/components/stack';
import { StyledUnorderedList } from '@cp/ds/src/components/styled/styledUnorderedList';
import { Box } from '@cp/ds/src/components/box';
import { StyledListItem } from '@cp/ds/src/components/styled/styledListItem';
import { Link } from '@cp/ds/src/components/link';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { Divider } from '@cp/ds/src/components/divider';
import { Typography } from '@cp/ds/src/components/typography';
import { useTheme } from '@cp/ds/src/hooks';
import { ContentSkeleton } from '@cp/ds/src/components/contentSkeleton';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds';
import { Grid } from '@cp/ds/src/components/grid';
import { Schema } from '@cp/ds/src/components/schema';
import { CATALOG_FEEDBACK_PAGINATION } from '@common/const/catalog/feedback';
import { FeedbackCard } from '../card';
import { FeedbackView } from '../view';
import { buildFeedbacksSchema } from '../../lib';
import { FeedbacksListProps } from './types';

export * from './types';

const defaultRenderItemFn: FeedbacksListProps['renderItem'] = ({ Component, props }) => {
  return <Component {...props} />;
};

export const FeedbacksList: React.FC<FeedbacksListProps> = ({
  productName,
  totalCount,
  averageRating,
  items,
  stats,
  isLoading,
  onLoadMore,
  canLoadMore = false,
  itemLayoutType = 'plain',
  hideTitle,
  renderItem = defaultRenderItemFn,
}) => {
  const theme = useTheme();
  const Feedback = {
    plain: FeedbackView,
    card: FeedbackCard,
  }[itemLayoutType];

  const schema = React.useMemo(() => {
    if (!productName || !averageRating || !totalCount || !items) {
      return;
    }

    return buildFeedbacksSchema({
      productName,
      averageRating,
      totalCount,
      items: items.slice(0, CATALOG_FEEDBACK_PAGINATION),
    });
  }, [items, productName, averageRating, totalCount]);

  return (
    <div>
      <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between', mb: itemLayoutType === 'card' ? 1.5 : 0 }}>
        {!hideTitle && (
          <Stack direction="row">
            <Typography component="div" variant="h3">
              Отзывы{' '}
            </Typography>
            {totalCount ? (
              <Typography component="div" sx={{ ml: 1, color: 'lightgray' }} variant="h3">
                ({totalCount})
              </Typography>
            ) : null}
          </Stack>
        )}
        {stats ? (
          <>
            <Stack direction="row">
              <Typography component="div" variant="h3">
                {feedbackSign({ isPositive: true })}
              </Typography>
              <Typography component="div" sx={{ ml: 0.5, color: stats.positiveCount ? undefined : 'lightgray' }} variant="h3">
                {stats.positiveCount}
              </Typography>
              <Typography component="div" sx={{ ml: 1.5 }} variant="h3">
                {feedbackSign({ isPositive: false })}
              </Typography>
              <Typography component="div" sx={{ ml: 0.5, color: stats.negativeCount ? undefined : 'lightgray' }} variant="h3">
                {stats.negativeCount}
              </Typography>
            </Stack>

            <Divider sx={{ mt: 1.5 }} />
          </>
        ) : null}
      </Stack>

      {schema && <Schema schema={schema} />}

      <Box>
        {items?.length ? (
          <Grid
            alignItems="stretch"
            component={StyledUnorderedList}
            container
            spacing={
              itemLayoutType === 'card' ? { xs: 1.5, [MOBILE_BREAKPOINT_THRESHOLD]: 2.5 } : { xs: 1.5, [MOBILE_BREAKPOINT_THRESHOLD]: 1 }
            }
          >
            {items.map((item) => {
              return (
                <Grid
                  component={StyledListItem}
                  item
                  key={item.id}
                  md={itemLayoutType === 'card' ? 4 : 12}
                  sx={{ display: 'flex', '& > *': { flexGrow: 1 } }}
                  xs={12}
                >
                  {renderItem({
                    Component: Feedback,
                    props: { data: item, feedbackCollapsedLength: 270, feedbackCollapsedMaxLength: 280 },
                  })}
                </Grid>
              );
            })}
          </Grid>
        ) : isLoading ? (
          <ContentSkeleton blocks={2} sx={{ width: 1 }} />
        ) : (
          <Typography component="div" sx={{ color: theme.palette['gray dark'], textAlign: 'center' }}>
            Нет отзывов
          </Typography>
        )}
      </Box>

      {canLoadMore &&
        (isLoading ? (
          <Skeleton sx={{ mt: 5.5, width: 100 }} />
        ) : (
          <Link onClick={onLoadMore} sx={{ mt: 5.5, display: 'inline-block' }}>
            Еще отзывы
          </Link>
        ))}
    </div>
  );
};
