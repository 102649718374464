import { ApiRoute } from './route';

export const apiRouteMethodDict: Record<ApiRoute, 'get' | 'post' | 'put' | 'delete'> = {
  [ApiRoute.AdminClientPatronageUpdate]: 'put',
  [ApiRoute.AdminClientPatronageGet]: 'get',

  [ApiRoute.AdminClientInterviewSchedule]: 'post',

  [ApiRoute.AdminCatalogItemsGet]: 'get',
  [ApiRoute.AdminCatalogItemGetOne]: 'get',
  [ApiRoute.AdminCatalogFeedbacksCreate]: 'post',
  [ApiRoute.AdminCatalogFeedbacksDelete]: 'delete',

  [ApiRoute.AdminResumesUpdate]: 'put',
  [ApiRoute.AdminResumesScheduleIntervalsCreate]: 'post',
  [ApiRoute.AdminResumesScheduleIntervalsDelete]: 'delete',

  [ApiRoute.BillingTariffs]: 'get',
  [ApiRoute.BillingTariffsGetOne]: 'get',
  [ApiRoute.BillingPaymentsCallbacksCloudpayments3DS]: 'post',
  [ApiRoute.CatalogOptionsGet]: 'get',

  [ApiRoute.ClientLogin]: 'post',
  [ApiRoute.ClientAuth]: 'post',
  [ApiRoute.ClientRegistration]: 'post',
  [ApiRoute.ClientDelete]: 'delete',
  [ApiRoute.ClientProfileGet]: 'get',
  [ApiRoute.ClientProfileUpdate]: 'put',
  [ApiRoute.ClientPrefillParametersGet]: 'get',

  [ApiRoute.ClientResumesGetOne]: 'get',
  [ApiRoute.ClientResumesUpdate]: 'put',
  [ApiRoute.ClientResumesScheduleIntervalsCreate]: 'post',
  [ApiRoute.ClientResumesScheduleIntervalsDelete]: 'delete',
  [ApiRoute.ClientResumesGet]: 'get',

  [ApiRoute.ClientVacanciesCreate]: 'post',
  [ApiRoute.ClientVacanciesUpdate]: 'put',
  [ApiRoute.ClientVacanciesDelete]: 'delete',
  [ApiRoute.ClientVacanciesGetOne]: 'get',
  [ApiRoute.ClientVacanciesGet]: 'get',

  [ApiRoute.ClientFavoriteClientsCreate]: 'post',
  [ApiRoute.ClientFavoriteClientsDelete]: 'delete',
  [ApiRoute.ClientFavoriteClientsGet]: 'get',

  [ApiRoute.ClientRegularPartnerClientsCreate]: 'post',
  [ApiRoute.ClientRegularPartnerClientsDelete]: 'delete',
  [ApiRoute.ClientRegularPartnerClientsGet]: 'get',

  [ApiRoute.ClientTrainingStart]: 'post',
  [ApiRoute.ClientTrainingFinish]: 'put',

  [ApiRoute.ClientsContactCanView]: 'get',
  [ApiRoute.ClientsContactGet]: 'get',
  [ApiRoute.ClientsResumeGet]: 'get',

  [ApiRoute.ClientBillingPaymentCardsCreate]: 'post',
  [ApiRoute.ClientBillingPaymentCardsGet]: 'get',
  [ApiRoute.ClientBillingPaymentCardsDelete]: 'delete',
  [ApiRoute.ClientBillingSubscriptionStart]: 'post',
  [ApiRoute.ClientBillingSubscriptionGet]: 'get',
  [ApiRoute.ClientBillingSubscriptionStop]: 'delete',

  [ApiRoute.CatalogItemsGet]: 'get',
  [ApiRoute.CatalogItemAuthorConnect]: 'post',
  [ApiRoute.CatalogItemResponse]: 'post',
  [ApiRoute.CatalogItemGetResponses]: 'get',
  [ApiRoute.CatalogItemGetProposals]: 'get',

  [ApiRoute.DealCalcPrice]: 'post',
  [ApiRoute.DealCreateWithClient]: 'post',
  [ApiRoute.DealCreateFromVacancy]: 'post',
  [ApiRoute.DealAccept]: 'put',
  [ApiRoute.DealReject]: 'put',
  [ApiRoute.DealUpdatePrice]: 'put',
  [ApiRoute.DealGetOne]: 'get',
  [ApiRoute.DealFeedbacksCreate]: 'post',

  [ApiRoute.StatsClientInteractionsGet]: 'get',
  [ApiRoute.StatsClientInteractionModeratorView]: 'put',
  [ApiRoute.StatsClientSubscriptions]: 'get',
  [ApiRoute.StatsVacancies]: 'get',
};
