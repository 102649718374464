import { PersistConfig, persistCombineReducers } from 'redux-persist';
import { StateFromReducersMapObject } from 'redux';
import { authenticationReducerPath, authenticationSlice, coreApi } from '@cp/shared/api/core';
import { localizationReducerPath, localizationSlice } from '@cp/shared/localization';
import { dialogReducerConfig } from '@cp/entities/dialog';
import { responseDelayReducerConfig } from 'src/features/responseDelay';
import { vacancyMonitorReducerConfig } from 'src/widgets/vacancyMonitor';
import { routesTableReducerConfig } from 'src/widgets/routesTable';
import { themeSlice } from './theme/reducer';
import { modal } from './modal';

const getReducers = () => ({
  ...responseDelayReducerConfig,
  ...dialogReducerConfig,
  ...vacancyMonitorReducerConfig,
  ...routesTableReducerConfig,
  [authenticationReducerPath]: authenticationSlice.reducer,
  [localizationReducerPath]: localizationSlice.reducer,
  theme: themeSlice.reducer,
  modal,
  [coreApi.reducerPath]: coreApi.reducer,
});

export type NotPersistedRootState = StateFromReducersMapObject<ReturnType<typeof getReducers>>;

export const createRootReducer = (persistConfig: PersistConfig<NotPersistedRootState>) => {
  const reducers = getReducers();

  return persistCombineReducers(persistConfig, reducers);
};
