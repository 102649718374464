import { ApiRoute } from './route';

export const apiRouteUrlDict: Record<ApiRoute, string> = {
  [ApiRoute.AdminClientPatronageUpdate]: 'admin/clients/:clientId/patronage',
  [ApiRoute.AdminClientPatronageGet]: 'admin/clients/patronage',

  [ApiRoute.AdminClientInterviewSchedule]: 'admin/clients/:clientId/interview/schedule',

  [ApiRoute.AdminCatalogItemsGet]: 'admin/catalog/items',
  [ApiRoute.AdminCatalogItemGetOne]: 'admin/catalog/items/:id',
  [ApiRoute.AdminCatalogFeedbacksCreate]: 'admin/catalog/feedbacks',
  [ApiRoute.AdminCatalogFeedbacksDelete]: 'admin/catalog/feedbacks/:id',

  [ApiRoute.AdminResumesUpdate]: 'admin/resumes/:id',
  [ApiRoute.AdminResumesScheduleIntervalsCreate]: 'admin/resumes/:id/schedule/intervals',
  [ApiRoute.AdminResumesScheduleIntervalsDelete]: 'admin/resumes/:id/schedule/intervals',

  [ApiRoute.BillingTariffs]: 'billing/tariffs',
  [ApiRoute.BillingTariffsGetOne]: 'billing/tariffs/:id',
  [ApiRoute.BillingPaymentsCallbacksCloudpayments3DS]: 'billing/payments/callbacks/cloudpayments/3ds',
  [ApiRoute.CatalogOptionsGet]: 'catalog/options',

  [ApiRoute.ClientLogin]: 'client/login',
  [ApiRoute.ClientAuth]: 'client/auth',
  [ApiRoute.ClientRegistration]: 'client/registration',
  [ApiRoute.ClientDelete]: 'client',
  [ApiRoute.ClientProfileGet]: 'client/profile',
  [ApiRoute.ClientProfileUpdate]: 'client/profile',
  [ApiRoute.ClientPrefillParametersGet]: 'client/prefill/parameters',

  [ApiRoute.ClientResumesGetOne]: 'client/resumes/:id',
  [ApiRoute.ClientResumesUpdate]: 'client/resumes/:id',
  [ApiRoute.ClientResumesScheduleIntervalsCreate]: 'client/resumes/:id/schedule/intervals',
  [ApiRoute.ClientResumesScheduleIntervalsDelete]: 'client/resumes/:id/schedule/intervals',
  [ApiRoute.ClientResumesGet]: 'client/resumes',

  [ApiRoute.ClientVacanciesCreate]: 'client/vacancies',
  [ApiRoute.ClientVacanciesUpdate]: 'client/vacancies/:id',
  [ApiRoute.ClientVacanciesDelete]: 'client/vacancies/:id',
  [ApiRoute.ClientVacanciesGetOne]: 'client/vacancies/:id',
  [ApiRoute.ClientVacanciesGet]: 'client/vacancies',

  [ApiRoute.ClientFavoriteClientsCreate]: 'client/favorites/clients',
  [ApiRoute.ClientFavoriteClientsDelete]: 'client/favorites/clients/:clientId',
  [ApiRoute.ClientFavoriteClientsGet]: 'client/favorites/clients',

  [ApiRoute.ClientRegularPartnerClientsCreate]: 'client/regular-partners/clients',
  [ApiRoute.ClientRegularPartnerClientsDelete]: 'client/regular-partners/clients/:clientId',
  [ApiRoute.ClientRegularPartnerClientsGet]: 'client/regular-partners/clients',

  [ApiRoute.ClientTrainingStart]: 'client/training',
  [ApiRoute.ClientTrainingFinish]: 'client/training',

  [ApiRoute.ClientBillingPaymentCardsCreate]: 'client/billing/payment-cards',
  [ApiRoute.ClientBillingPaymentCardsGet]: 'client/billing/payment-cards',
  [ApiRoute.ClientBillingPaymentCardsDelete]: 'client/billing/payment-cards/:id',

  [ApiRoute.ClientBillingSubscriptionStart]: 'client/billing/subscription',
  [ApiRoute.ClientBillingSubscriptionGet]: 'client/billing/subscription',
  [ApiRoute.ClientBillingSubscriptionStop]: 'client/billing/subscription',

  [ApiRoute.ClientsContactCanView]: 'clients/:clientId/contacts/can-view',
  [ApiRoute.ClientsContactGet]: 'clients/:clientId/contacts',
  [ApiRoute.ClientsResumeGet]: 'clients/:clientId/resume',

  [ApiRoute.CatalogItemsGet]: 'catalog/items',
  [ApiRoute.CatalogItemAuthorConnect]: 'catalog/items/:id/author/connect',
  [ApiRoute.CatalogItemResponse]: 'catalog/items/:id/response',
  [ApiRoute.CatalogItemGetResponses]: 'catalog/items/:catalogItemId/responses',
  [ApiRoute.CatalogItemGetProposals]: 'catalog/items/:catalogItemId/proposals',

  [ApiRoute.DealCalcPrice]: 'deals/calc-price',
  [ApiRoute.DealCreateWithClient]: 'deals/create-with-client',
  [ApiRoute.DealCreateFromVacancy]: 'deals/create-from-vacancy',
  [ApiRoute.DealAccept]: 'deals/:dealId/accept',
  [ApiRoute.DealReject]: 'deals/:dealId/reject',
  [ApiRoute.DealUpdatePrice]: 'deals/:dealId/update-price',
  [ApiRoute.DealGetOne]: 'deals/:dealId',
  [ApiRoute.DealFeedbacksCreate]: 'deals/:dealId/feedbacks',

  [ApiRoute.StatsClientInteractionsGet]: 'stats/interactions',
  [ApiRoute.StatsClientInteractionModeratorView]: 'stats/interactions/:id/view',
  [ApiRoute.StatsClientSubscriptions]: 'stats/billing/subscriptions',
  [ApiRoute.StatsVacancies]: 'stats/catalog/vacancies',
};
