import React from 'react';
import { EditableSection } from '@cp/ds/src/components/editableSection';
import { FeedbacksList, FeedbacksListProps } from '@cp/entities/feedback';
import { coreCatalogItemApi } from '@cp/entities/catalogItem';
import { CLIENT_CATALOG_REVIEW_PAGINATION_SIZE_MAX } from '@common/const/catalog';
import { ContentSkeleton } from '@cp/ds/src/components/contentSkeleton';
import { useModal } from 'src/app/shared/hooks/useModal';
import { CLIENT_FEEDBACKS_EDIT_MODAL_NAME } from 'src/app/shared/components/modals/clientFeedbacksEdit/const';
import { DeleteFeedbackButton } from 'src/features/deleteFeedback';
import { ClientFeedbacksEditableSectionProps } from './model';

const renderFeedbackFn: FeedbacksListProps['renderItem'] = ({ Component, props }) => {
  return <Component {...props} actions={<DeleteFeedbackButton id={props.data.id} />} />;
};

export const ClientFeedbacksEditableSection: React.FC<ClientFeedbacksEditableSectionProps> = ({ clientId, mainResumeId }) => {
  const { openModal } = useModal();
  const { data: feedbacksData, isLoading } = coreCatalogItemApi.endpoints.getFeedbacks.useQuery(
    { id: mainResumeId as number, pagination: CLIENT_CATALOG_REVIEW_PAGINATION_SIZE_MAX },
    { skip: !mainResumeId },
  );

  return (
    <EditableSection
      onEdit={() => {
        if (!clientId) {
          throw new Error('clientId is required');
        }
        openModal(CLIENT_FEEDBACKS_EDIT_MODAL_NAME, { clientId, mainResumeItemId: mainResumeId ?? undefined });
      }}
      title={<>Отзывы</>}
    >
      {isLoading ? <ContentSkeleton blocks={1} /> : <FeedbacksList {...feedbacksData} hideTitle renderItem={renderFeedbackFn} />}
    </EditableSection>
  );
};
